import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components"

const ScanImage = styled.img`
  border: 5px solid ${props => {
    return props.clean ? props.theme.colors.clean : props.theme.colors.risk
  }};
  width: 100%;
  height: auto
`

const ImageContainer = styled.div`
  max-width: ${props => props.maxWidth}px
`


const NoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${props => props.maxWidth}px;
  height: ${props => {
    return (props.maxWidth / 100 * 77) + 'px'   
  }};
  border: 5px solid ${props => {
    return props.clean ? props.theme.colors.clean : props.theme.colors.risk
  }};
`

export const Screenshot = ({imageData, clean, maxWidth, ...props}) => {
    if (imageData) {
        return (
            <ImageContainer maxWidth={maxWidth}>
                <ScanImage src={`data:image/png;base64,${imageData}`} clean={clean} {...props}/>
            </ImageContainer>
        )
    } else {
        return <NoImage clean={clean} maxWidth={maxWidth}>SCREENSHOT UNAVAILABLE</NoImage>
    }
}


Screenshot.propTypes = {
    /**
     * The base64 hash of the screenshot
     */
    imageData: PropTypes.string,
    /**
     * Whether the source page is clean or not
     */
    clean: PropTypes.bool,
    /**
     * The maximum width of the image container
     */
    maxWidth: PropTypes.number
}

Screenshot.defaultProps = {
    imageData: '',
    clean: true,
    maxWidth: 500
}
// Note:  These values are in additional to the ones set in GlobalStyles.js

const defaultValues = {
    id: 'T_001',
    name: 'Default',
    baseColors: {
        green: '#5cb85c',
        white: '#fff',
        red: 'crimson',
        black: '#222'
    },
    body: {
        backgroundColor: '#fff',
        text: '#222',
        font: "Raleway,HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif"
    }
}

export const Base = (themeValues) => {

    if (typeof themeValues === 'undefined' || themeValues === null) {
        themeValues = defaultValues
    }
    return {
        id: themeValues.id,
        name: themeValues.name,
        colors: {
            body: themeValues.body.backgroundColor,
            text: themeValues.body.text,
            base: themeValues.baseColors,
            clean: themeValues.baseColors.green,
            risk: themeValues.baseColors.red,
            button: {
                clean: {
                    text: themeValues.baseColors.white,
                    background: themeValues.baseColors.green
                },
                warn: {
                    text: themeValues.baseColors.white,
                    background: themeValues.baseColors.red
                },
                default: {
                    text: themeValues.baseColors.black,
                    background: "lightgrey"
                }
            },
            spinners: {
                scanner: {
                    scanning: themeValues.baseColors.green,
                    clean: themeValues.baseColors.green,
                    warn: themeValues.baseColors.red,
                    border: "grey"
                }
            },
            link: {
                text: "#4682b4",
                opacity: 1
            },
            list: {
                summary: {
                    default: {
                        text: themeValues.body.text,
                        fontWeight: 'normal'
                    },
                    reputation: {
                        text: themeValues.baseColors.green,
                        fontWeight: 'normal'

                    },
                    risks: {
                        text: themeValues.baseColors.red,
                        fontWeight: 600
                    }
                }
            }
        },
        widths: {
            spinners: {
                scanner: "2px"
            }
        },
        font: themeValues.body.font
    }
}
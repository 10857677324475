import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import './scanner.css';
/**
 * UI component for indicating a scan is taking place
 */

const StyledOuter = styled.div`
  border: 2px solid; 
  border-color: ${props => {
    let top, right, bottom, left
    switch (props.status) {
      case 'clean':
        top = props.theme.colors.spinners.scanner.clean
        right = props.theme.colors.spinners.scanner.clean
        bottom = props.theme.colors.spinners.scanner.clean
        left = props.theme.colors.spinners.scanner.clean
        return `${top} ${right} ${bottom} ${left}`
      case 'warn':
        top = props.theme.colors.spinners.scanner.warn
        right = props.theme.colors.spinners.scanner.warn
        bottom = props.theme.colors.spinners.scanner.warn
        left = props.theme.colors.spinners.scanner.warn
        return `${top} ${right} ${bottom} ${left}`
      default:
        top = props.theme.colors.spinners.scanner.border
        right = props.theme.colors.spinners.scanner.border
        bottom = props.theme.colors.spinners.scanner.border
        left = props.theme.colors.spinners.scanner.scanning
        return `${top} ${right} ${bottom} ${left}`

    }
  }};

`
const StyledInner = styled.div`
`


export const Scanner = ({status, ...props}) => {
    let scanCircleClass;
    switch (status) {
        case 'clean':
            scanCircleClass = 'scanner scan-clean-complete-circle'
            break;
        case 'warn':
            scanCircleClass = 'scanner scan-warn-complete-circle'
            break;
        default:
            scanCircleClass = 'scanner'
    }
    const platform = window.navigator.platform
    let warningClass = 'check draw scan-warn-complete'
    if (platform.startsWith('Mac')){
        warningClass = 'check draw scan-warn-complete-mac'
    }
    return (
        <StyledOuter
            className={`${scanCircleClass} ${props.className}`}
            status={status}
            {...props}
        >
            {status === 'clean' &&
            <StyledInner className="check draw scan-clean-complete"/>
            }
            {status === 'warn' &&
            <StyledInner className={warningClass}/>
            }
        </StyledOuter>
    );
};

Scanner.propTypes = {
    /**
     * The status of the scan
     */
    status: PropTypes.oneOf(['scan', 'clean', 'warn']),
};

Scanner.defaultProps = {
    status: 'scan'
};

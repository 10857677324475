import React from "react";
import {SimpleVersion} from "../stories/version/simple/simple";
import styled from "styled-components";

const StyledDiv = styled.div`
  position: absolute;
  bottom: 5px;
  left: 10px;
  
`
export const Version = ({version}) => {
    return (
        <StyledDiv>
            <SimpleVersion version={version}/>
        </StyledDiv>
    )
}
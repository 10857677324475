import React from "react";
import {UrlList} from "../stories/lists/url-list/url-list";
import {SummaryList} from "../stories/lists/summary-list/summary-list";
import {Button} from "../stories/buttons/button";
import styled from "styled-components";


export const Details = ({results, status, autoContinue}) => {
    let listText,
        listType,
        listItems,
        buttonType,
        buttonText,
        urlText

    if (results.risks.length === 0) {
        if (results.redirects.length > 0) {
            urlText = 'The link has {count} redirects'
        } else {
            urlText = 'The link has 1 destination'
        }
        if (results.content.length > 0) {
            listText = 'The link has the following potential reputation:'
            listType = 'reputation'
            listItems = results.content;
        }

        buttonType = 'clean';
        buttonText = 'CONTINUE';
    } else {
        if (results.redirects.length > 0) {
            urlText = 'The link has {count} redirects'
        } else {
            urlText = 'The link has 1 destination'
        }
        listText = 'The link has the following potential risks:'
        listType = 'risks'
        listItems = results.risks
        buttonType = 'warn';
        buttonText = 'CONTINUE WITH CAUTION';

    }
    if (results.isSecure === false && results.isFile === true) {
        listType = 'default'
    }
    return (
        <div>
            <ColumnTitle>About this link</ColumnTitle>
            <UrlList expandable={true}
                     urls={results.redirects.length > 0 ? results.redirects : [results.url]} text={urlText}
                     urlLength={100}/>
            {listType !== null &&
                <SummaryList text={listText} items={listItems}
                             type={listType}/>
            }
            {results.risks.length > 0 &&

                <AdviceDiv>Visiting the link may harm your computer or cause your personal information to be
                    stolen.</AdviceDiv>}
            {results.risks.length > 0 && <div><AdviceDiv>We recommend that you do not continue.</AdviceDiv>
                <Spacer></Spacer>
            </div>
            }

            {(status === 'clean' || (status === 'warn' && [2, 3, 4, 5].includes(autoContinue) === false)) && listType !== 'default' &&
                <div><Button onClick={() => {
                    window.location.replace(results.url);
                }} label={buttonText} buttonType={buttonType}
                /> <Spacer/></div>
            }

            {results.isSecure === false && results.isFile === true &&
                <div>
                    <WarningDiv>
                        This link is attempting to redirect or download a file from an insecure (HTTP)
                        website.
                    </WarningDiv>
                    <WarningDiv>
                        Due to the insecure link, we are unable to redirect you automatically or provide a Continue
                        button.
                    </WarningDiv>
                </div>
            }

        </div>
    )
}

const ColumnTitle = styled.h4`
  margin-bottom: 30px;
`

const AdviceDiv = styled.div`
  text-align: left;
  margin-top: 20px;
`

const WarningDiv = styled.div`
  text-align: left;
  margin-top: 20px;
  color: crimson;
`

const Spacer = styled.div`
  height: 20px`


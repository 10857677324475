import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import './button.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

/**
 * Primary UI component for user interaction
 */

const StyledButton = styled.button`
  cursor: pointer;
  background-color: ${props => {
    switch (props.buttonType) {
      case 'clean':
        return props.theme.colors.button.clean.background
      case 'warn':
        return props.theme.colors.button.warn.background
      default:
        return props.theme.colors.button.default.background
    }
  }};
  color: ${props => {
    switch (props.buttonType) {
      case 'clean':
        return props.theme.colors.button.clean.text
      case 'warn':
        return props.theme.colors.button.warn.text
      default:
        return props.theme.colors.button.default.text
    }
  }};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  cursor: pointer;
`;


export const Button = ({buttonType, label,icon, ...props}) => {
    return (
        <StyledButton
            type="button"
            buttonType={buttonType}
            {...props}
        >
            {buttonType==='warn' &&  <StyledIcon icon={faExclamationTriangle}/>} {label}
        </StyledButton>
    );
};

Button.propTypes = {
    /**
     * Which button type to display
     */
    buttonType: PropTypes.oneOf(['default', 'clean', 'warn']),
    /**
     * How large should the button be?
     */
    label: PropTypes.string.isRequired,
    /**
     * The icon to show
     */
    icon: PropTypes.elementType,
    /**
     * Optional click handler
     */
    onClick: PropTypes.func,

};

Button.defaultProps = {
    buttonType: 'default',
    size: 'medium',
    onClick: undefined,
    icon: ''
};

import {Screenshot} from "../stories/screenshot/screenshot";
import React from "react";
import styled from "styled-components";


export const Preview = ({titleText,maxWidth,clean,imageData}) => {

    return (
        <div>
            <ColumnTitle>{titleText}</ColumnTitle>
            <Screenshot maxWidth={maxWidth} clean={clean} imageData={imageData}/>
        </div>
    )
}

const ColumnTitle = styled.h4`
  margin-bottom: 30px;
`